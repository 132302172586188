import $ from 'jquery'
import util from 'utils/MiscUtils'
import ReactRailsUJS from 'react_ujs'

function closeBsModal() {
  // Attempt to close the modal properly
  $('.modal-dialog .close').click()

  // Clean up artifacts from any potential failure
  $('.modal').modal('hide')
  $('.modal-backdrop').remove()
  $('body').removeClass('modal-open')
}

function updatePersonDropdown(event, fieldName = 'role_id') {
  setTimeout(() => {
    const form = $(event.target).closest('form')
    const assignmentMap = JSON.parse($('#assignment-map')[0].dataset.assignmentMap)
    const personReactSelect = $(form).find('[name$="person_id]"]').closest('[data-react-class]')[0]
    const currentProps = JSON.parse(personReactSelect.dataset.reactProps)
    const filteredPeople = []
    const {roleId, circleId} = getRoleAndCircleId(event, fieldName, form)

    assignmentMap.forEach((person) => {
      if (person.roles.includes(roleId) && (!circleId || person.circles.includes(circleId)))
        filteredPeople.push({label: person.name, value: person.id})
    })
    currentProps.options = filteredPeople
    personReactSelect.dataset.reactProps = JSON.stringify(currentProps)

    ReactRailsUJS.mountComponents(personReactSelect.parentElement)
  }, 50)
}

function getRoleAndCircleId(event, fieldName, form) {
  const fieldElement = $(form).find(`[name$="${fieldName}]"]`)[0]

  if (!fieldElement || fieldElement.value === '')
    return {}

  const fieldValue = fieldElement.value

  if (fieldName && fieldName === 'role_id')
    return {roleId: parseInt(fieldValue, 10) || -1}
  if (fieldName === 'role_id_circle_id') {
    const [roleId, circleId] = fieldValue.split(',').map((id) => parseInt(id, 10) || -1)
    return {roleId, circleId}
  }
  return {}
}

function setModified($form) {
  $form.data('modified', true)
}

function queueSubmit($form, timeout = 100) {
  const queuedFn = setTimeout(() => {
    if ($form.data('modified'))
      $form[0].requestSubmit()
  }, timeout)
  $form.data('timeout', queuedFn)
}

function dequeueSubmit($form) {
  window.clearTimeout($form.data('timeout'))
  $form.data('timeout', null)
}

function setupMountComponents(parentContainerId) {
  setTimeout(() => window.ReactRailsUJS.mountComponents(`#${parentContainerId}`), 50)
}

function setupRolePersonDropdown(formId, fieldName = 'role_id') {
  setTimeout(() => {
    const $form = $(`#${formId}`)
    $form.on('change', 'div.js-slick-update-person', (e) => {
      if ($(e.target).hasClass('js-slick-update-person'))
        updatePersonDropdown(e, fieldName)
    })
  }, 50)
}

function setupSaveOnFormBlur(formId) {
  setTimeout(() => {
    const $form = $(`#${formId}`)
    $form.on('change', 'input, textarea, select, div.js-slick-auto-save', () => setModified($form))
    $form.on('blur', 'input, textarea, select', () => queueSubmit($form, 30))
    $form.on('focus', 'input, textarea, select', () => dequeueSubmit($form))

    $form.on('change', 'input, textarea, select, div.js-slick-auto-save', (e) => util.warn('$form change', e))
    $form.on('blur', 'input, textarea, select', (e) => util.warn('$form blur', e))
    $form.on('focus', 'input, textarea, select', (e) => util.warn('$form focus', e))
  }, 150)
}

function setupSaveOnInputBlur(formId) {
  setTimeout(() => {
    const $form = $(`#${formId}`)
    $form.on('change', 'input, textarea, select', (e) => util.warn('$form change', e))
    $form.on('blur', 'input, textarea, select', (e) => util.warn('$form blur', e))
    $form.on('focus', 'input, textarea, select', (e) => util.warn('$form focus', e))
  }, 150)
}

export default {closeBsModal, setupMountComponents, setupRolePersonDropdown, setupSaveOnFormBlur, setupSaveOnInputBlur}
