// @flow
import type {Node} from 'react'
import React from 'react'

import AppLink from 'components/ui/AppLink'
import VisuallyHidden from 'components/ui/VisuallyHidden'

type Props = $ReadOnly<{
  href: string,
  linkText: string,
  children: Node,
  className: string,
  ...
}>

// <VisuallyHidden /> usage make
//    <IconLink href="/path/ linkText="My awesome page" />
//  be equal to
//    <a href="/path">My awsome text</a>
//  for screen reader
function IconLink({href, linkText, children, className, ...props}: Props): Node {
  return (
    <AppLink
      {...props}
      className={className}
      href={href}
      title={linkText}
    >
      {children}
      <VisuallyHidden>{linkText}</VisuallyHidden>
    </AppLink>
  )
}

export default IconLink
