/**
 * @flow
 * @relayHash 577baed7fc0b5e7a0d4967dccf5d6967
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProjectsGroupsOrGrid_circle$ref = any;
type ProjectsGroupsOrGrid_projects$ref = any;
type UpdateProjectModal_project$ref = any;
export type SupportingProjectsListQueryVariables = {|
  goalId: string,
  circleId: string,
|};
export type SupportingProjectsListQueryResponse = {|
  +goal: ?{|
    +id?: string,
    +supportingProjects?: ?{|
      +nodes: ?$ReadOnlyArray<?{|
        +id: string,
        +$fragmentRefs: ProjectsGroupsOrGrid_projects$ref & UpdateProjectModal_project$ref,
      |}>
    |},
    +targetSupportingProjects?: ?{|
      +nodes: ?$ReadOnlyArray<?{|
        +id: string,
        +$fragmentRefs: ProjectsGroupsOrGrid_projects$ref & UpdateProjectModal_project$ref,
      |}>
    |},
  |},
  +circle: ?{|
    +id?: string,
    +$fragmentRefs: ProjectsGroupsOrGrid_circle$ref,
  |},
|};
export type SupportingProjectsListQuery = {|
  variables: SupportingProjectsListQueryVariables,
  response: SupportingProjectsListQueryResponse,
|};
*/


/*
query SupportingProjectsListQuery(
  $goalId: ID!
  $circleId: ID!
) {
  goal: node(id: $goalId) {
    __typename
    ... on Goal {
      id
      supportingProjects {
        nodes {
          id
          ...ProjectsGroupsOrGrid_projects
          ...UpdateProjectModal_project
        }
      }
      targetSupportingProjects {
        nodes {
          id
          ...ProjectsGroupsOrGrid_projects
          ...UpdateProjectModal_project
        }
      }
    }
    id
  }
  circle: node(id: $circleId) {
    __typename
    ... on Circle {
      id
      ...ProjectsGroupsOrGrid_circle
    }
    id
  }
}

fragment DraggableProjectCard_circle on Circle {
  ...ProjectCard_circle
}

fragment DraggableProjectCard_project on Project {
  id
  viewerCanUpdate
  ...ProjectCard_project
}

fragment Group_circle on Circle {
  ...Project_circle
}

fragment Group_projects on Project {
  id
  databaseId
  status
  description(format: MARKDOWN)
  createdAt
  roi
  organization {
    viewerIsMember
    id
  }
  circle {
    shortName
    id
  }
  ...Project_project
}

fragment NoteTooltip_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment PersonAvatar_person on Person {
  avatarUrl
  name
}

fragment ProjectCard_circle on Circle {
  id
}

fragment ProjectCard_project on Project {
  databaseId
  status
  createdAt
  description(format: MARKDOWN)
  privateToCircle
  viewerCanUpdate
  roi
  parentGoalOrTarget
  parentGoalOrTargetDescription
  role {
    localizedName
    id
  }
  organization {
    isROIEnabled
    id
  }
  person {
    name
    ...PersonAvatar_person
    id
  }
  circle {
    id
    localizedName
  }
  ...ProjectLinkIcon_project
  ...ProjectDescription_project
}

fragment ProjectDescription_project on Project {
  description(format: MARKDOWN)
  organization {
    ...WYSIWYGViewerWithFeatureFlag_organization
    id
  }
}

fragment ProjectDetails_circle on Circle {
  id
}

fragment ProjectDetails_project on Project {
  databaseId
  createdAt
  privateToCircle
  privateToPerson
  link
  note
  roi
  parentGoalOrTarget
  parentGoalOrTargetDescription
  organization {
    isROIEnabled
    ...NoteTooltip_organization
    id
  }
  person {
    name
    id
  }
  circle {
    localizedName
    id
  }
  role {
    localizedName
    id
  }
}

fragment ProjectLinkIcon_project on Project {
  link
}

fragment Project_circle on Circle {
  ...ProjectDetails_circle
}

fragment Project_project on Project {
  id
  status
  viewerCanUpdate
  visibility
  privateToPerson
  projectDetailsPagePath(backUrl: "/my_projects")
  role {
    isLinked
    id
  }
  circle {
    databaseId
    id
  }
  organization {
    isApmBetaEnabled
    id
  }
  ...ProjectDetails_project
  ...ProjectDescription_project
}

fragment ProjectsGrid_circle on Circle {
  ...ProjectsStatusColumn_circle
}

fragment ProjectsGroupsOrGrid_circle on Circle {
  circleLeadRole {
    assignments {
      edges {
        node {
          person {
            id
            name
          }
          id
        }
      }
    }
    id
  }
  ...Group_circle
  ...ProjectsGrid_circle
}

fragment ProjectsGroupsOrGrid_projects on Project {
  status
  description(format: MARKDOWN)
  createdAt
  roi
  privateToPerson
  circle {
    supportedRole {
      id
    }
    id
    databaseId
    shortName
  }
  role {
    id
    localizedName
    nameWithLinkedTo: localizedName(with: CIRCLE_LINKED_TO)
    nameWithLinkedFrom: localizedName(with: CIRCLE_LINKED_FROM)
    isSupported
    innerCircle {
      id
    }
  }
  person {
    id
    name
  }
  ...Group_projects
  ...ProjectsStatusColumn_projects
}

fragment ProjectsStatusColumn_circle on Circle {
  ...DraggableProjectCard_circle
}

fragment ProjectsStatusColumn_projects on Project {
  id
  status
  ...DraggableProjectCard_project
}

fragment UpdateProjectModal_project on Project {
  id
  databaseId
  description(format: MARKDOWN)
  rawDescription: description(format: RAW)
  link
  visibility
  value
  effort
  status
  note
  parentGoalOrTarget
  viewerCanAddActions
  viewerCanUpdate
  waitingOnWho
  waitingOnWhat
  accountability {
    id
  }
  circle {
    databaseId
    supportedRole {
      id
    }
    organization {
      isWysiwygEnabled
      id
    }
    id
  }
  person {
    id
  }
  role {
    id
  }
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "circleId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "goalId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "goalId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "nodes",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProjectsGroupsOrGrid_projects"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "UpdateProjectModal_project"
      }
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "circleId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = [
  (v3/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWysiwygEnabled",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "nodes",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "format",
            "value": "MARKDOWN"
          }
        ],
        "kind": "ScalarField",
        "name": "description",
        "storageKey": "description(format:\"MARKDOWN\")"
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "roi",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "privateToPerson",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Circle",
        "kind": "LinkedField",
        "name": "circle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "supportedRole",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortName",
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v9/*: any*/),
          {
            "alias": "nameWithLinkedTo",
            "args": [
              {
                "kind": "Literal",
                "name": "with",
                "value": "CIRCLE_LINKED_TO"
              }
            ],
            "kind": "ScalarField",
            "name": "localizedName",
            "storageKey": "localizedName(with:\"CIRCLE_LINKED_TO\")"
          },
          {
            "alias": "nameWithLinkedFrom",
            "args": [
              {
                "kind": "Literal",
                "name": "with",
                "value": "CIRCLE_LINKED_FROM"
              }
            ],
            "kind": "ScalarField",
            "name": "localizedName",
            "storageKey": "localizedName(with:\"CIRCLE_LINKED_FROM\")"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSupported",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Circle",
            "kind": "LinkedField",
            "name": "innerCircle",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLinked",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Person",
        "kind": "LinkedField",
        "name": "person",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerIsMember",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isApmBetaEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isROIEnabled",
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "viewerCanUpdate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "visibility",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "backUrl",
            "value": "/my_projects"
          }
        ],
        "kind": "ScalarField",
        "name": "projectDetailsPagePath",
        "storageKey": "projectDetailsPagePath(backUrl:\"/my_projects\")"
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "privateToCircle",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "link",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "note",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parentGoalOrTarget",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "parentGoalOrTargetDescription",
        "storageKey": null
      },
      {
        "alias": "rawDescription",
        "args": [
          {
            "kind": "Literal",
            "name": "format",
            "value": "RAW"
          }
        ],
        "kind": "ScalarField",
        "name": "description",
        "storageKey": "description(format:\"RAW\")"
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "effort",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "viewerCanAddActions",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "waitingOnWho",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "waitingOnWhat",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Accountability",
        "kind": "LinkedField",
        "name": "accountability",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SupportingProjectsListQuery",
    "selections": [
      {
        "alias": "goal",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectConnection",
                "kind": "LinkedField",
                "name": "supportingProjects",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectConnection",
                "kind": "LinkedField",
                "name": "targetSupportingProjects",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Goal",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "circle",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProjectsGroupsOrGrid_circle"
              }
            ],
            "type": "Circle",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SupportingProjectsListQuery",
    "selections": [
      {
        "alias": "goal",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectConnection",
                "kind": "LinkedField",
                "name": "supportingProjects",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectConnection",
                "kind": "LinkedField",
                "name": "targetSupportingProjects",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Goal",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "circle",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "circleLeadRole",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AssignmentConnection",
                    "kind": "LinkedField",
                    "name": "assignments",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Assignment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Person",
                                "kind": "LinkedField",
                                "name": "person",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Circle",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "577baed7fc0b5e7a0d4967dccf5d6967",
    "metadata": {},
    "name": "SupportingProjectsListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '606019917f6546658e0f382b082407ef';

module.exports = node;
