/**
 * @flow
 * @relayHash 61b82df26ca46a1670c34ee1e9d17bfa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Project_project$ref = any;
type TacticalMeetingProjectsView_role$ref = any;
export type AlertSeverity = "ERROR" | "INFO" | "SUCCESS" | "WARNING";
export type ProjectVisibility = "ALL" | "PRIVATE_TO_CIRCLE" | "PRIVATE_TO_PERSON";
export type RoleOwner = "INDIVIDUAL_INITIATIVE" | "ROLE";
export type AddProjectInput = {|
  context: MutationContext,
  accountabilityId?: ?string,
  description?: ?string,
  personId?: ?string,
  tensionId?: ?string,
  roleId: string,
  roleType: RoleOwner,
  parentGoalOrTarget?: ?string,
  link?: ?any,
  visibility?: ?ProjectVisibility,
  value?: ?number,
  effort?: ?number,
  status?: ?string,
  note?: ?string,
  waitingOnWho?: ?string,
  waitingOnWhat?: ?string,
|};
export type MutationContext = {|
  organizationId: string
|};
export type AddProjectMutationVariables = {|
  input: AddProjectInput
|};
export type AddProjectMutationResponse = {|
  +addProject: ?{|
    +projectEdge: ?{|
      +node: ?{|
        +databaseId: string,
        +tension: ?{|
          +processed: boolean
        |},
        +circle: {|
          +supportedRole: ?{|
            +$fragmentRefs: TacticalMeetingProjectsView_role$ref
          |}
        |},
        +$fragmentRefs: Project_project$ref,
      |}
    |},
    +errors: $ReadOnlyArray<{|
      +path: ?string,
      +message: string,
      +severity: AlertSeverity,
    |}>,
  |}
|};
export type AddProjectMutation = {|
  variables: AddProjectMutationVariables,
  response: AddProjectMutationResponse,
|};
*/


/*
mutation AddProjectMutation(
  $input: AddProjectInput!
) {
  addProject(input: $input) {
    projectEdge {
      node {
        databaseId
        ...Project_project
        tension {
          processed
          id
        }
        circle {
          supportedRole {
            ...TacticalMeetingProjectsView_role
            id
          }
          id
        }
        id
      }
    }
    errors {
      path
      message
      severity
    }
  }
}

fragment CircleAndRoleSelector_viewer on Person {
  circles(first: 100) {
    edges {
      node {
        id
        supportedRole {
          id
          isDisplayedAsCircle
          ...RoleSelector_roles
        }
      }
    }
  }
  ...PersonRoleSelector_person
  ...PersonCircleSelector_person
}

fragment CircleSelector_circles on Circle {
  id
  databaseId
  localizedName
  isGovernanceEnabled
  supportedRole {
    isDisplayedAsCircle
    id
  }
}

fragment CreateProjectModal_circle on Circle {
  ...ProjectFormModalFragmented_circle
}

fragment CreateProjectModal_organization on Organization {
  ...ProjectFormModalFragmented_organization
}

fragment DraggableProjectCard_circle on Circle {
  ...ProjectCard_circle
}

fragment DraggableProjectCard_project on Project {
  id
  viewerCanUpdate
  ...ProjectCard_project
}

fragment Group_circle on Circle {
  ...Project_circle
}

fragment Group_projects on Project {
  id
  databaseId
  status
  description(format: MARKDOWN)
  createdAt
  roi
  organization {
    viewerIsMember
    id
  }
  circle {
    shortName
    id
  }
  ...Project_project
}

fragment NoteTooltip_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment PeopleSelector_people on Person {
  id
  databaseId
  name
}

fragment PersonAvatar_person on Person {
  avatarUrl
  name
}

fragment PersonCircleSelector_person on Person {
  circles(first: 100) {
    edges {
      node {
        ...CircleSelector_circles
        id
      }
    }
  }
}

fragment PersonRoleSelector_person on Person {
  roles {
    edges {
      node {
        circle {
          supportedRole {
            isDisplayedAsCircle
            id
          }
          id
        }
        ...RoleSelector_roles_4EQjyB
        id
      }
    }
  }
}

fragment ProjectCard_circle on Circle {
  id
}

fragment ProjectCard_project on Project {
  databaseId
  status
  createdAt
  description(format: MARKDOWN)
  privateToCircle
  viewerCanUpdate
  roi
  parentGoalOrTarget
  parentGoalOrTargetDescription
  role {
    localizedName
    id
  }
  organization {
    isROIEnabled
    id
  }
  person {
    name
    ...PersonAvatar_person
    id
  }
  circle {
    id
    localizedName
  }
  ...ProjectLinkIcon_project
  ...ProjectDescription_project
}

fragment ProjectDescription_project on Project {
  description(format: MARKDOWN)
  organization {
    ...WYSIWYGViewerWithFeatureFlag_organization
    id
  }
}

fragment ProjectDetails_circle on Circle {
  id
}

fragment ProjectDetails_project on Project {
  databaseId
  createdAt
  privateToCircle
  privateToPerson
  link
  note
  roi
  parentGoalOrTarget
  parentGoalOrTargetDescription
  organization {
    isROIEnabled
    ...NoteTooltip_organization
    id
  }
  person {
    name
    id
  }
  circle {
    localizedName
    id
  }
  role {
    localizedName
    id
  }
}

fragment ProjectEditForm_circle on Circle {
  ...ProjectOwnerSelector_circle
  roles(includeFocusRoles: true) {
    edges {
      node {
        id
        ...RoleAccountabilitySelector_role
      }
    }
  }
}

fragment ProjectEditForm_organization on Organization {
  databaseId
  isGoalsEnabled
  isROIEnabled
  supportableGoalsAndTargets
  onRestrictedPlan
  showAccountabilities: featureEnabled(name: "accountabilities")
  ...WYSIWYGTextareaWithFeatureFlag_organization
}

fragment ProjectEditForm_viewer on Person {
  ...ProjectOwnerSelector_viewer
  roles {
    edges {
      node {
        id
        ...RoleAccountabilitySelector_role
      }
    }
  }
}

fragment ProjectFormModalFragmented_circle on Circle {
  ...ProjectFormModalView_circle
}

fragment ProjectFormModalFragmented_organization on Organization {
  viewer {
    ...ProjectFormModalView_viewer
    id
  }
  ...ProjectFormModalView_organization
}

fragment ProjectFormModalView_circle on Circle {
  ...ProjectEditForm_circle
}

fragment ProjectFormModalView_organization on Organization {
  ...ProjectEditForm_organization
}

fragment ProjectFormModalView_viewer on Person {
  ...ProjectEditForm_viewer
  ...UpdateTensionInlineBox_viewer
}

fragment ProjectLinkIcon_project on Project {
  link
}

fragment ProjectOwnerSelector_circle on Circle {
  ...RoleAndPersonSelector_circle
}

fragment ProjectOwnerSelector_viewer on Person {
  ...CircleAndRoleSelector_viewer
}

fragment Project_circle on Circle {
  ...ProjectDetails_circle
}

fragment Project_project on Project {
  id
  status
  viewerCanUpdate
  visibility
  privateToPerson
  projectDetailsPagePath(backUrl: "/my_projects")
  role {
    isLinked
    id
  }
  circle {
    databaseId
    id
  }
  organization {
    isApmBetaEnabled
    id
  }
  ...ProjectDetails_project
  ...ProjectDescription_project
}

fragment ProjectsGrid_circle on Circle {
  ...ProjectsStatusColumn_circle
}

fragment ProjectsGroupsOrGrid_circle on Circle {
  circleLeadRole {
    assignments {
      edges {
        node {
          person {
            id
            name
          }
          id
        }
      }
    }
    id
  }
  ...Group_circle
  ...ProjectsGrid_circle
}

fragment ProjectsGroupsOrGrid_projects on Project {
  status
  description(format: MARKDOWN)
  createdAt
  roi
  privateToPerson
  circle {
    supportedRole {
      id
    }
    id
    databaseId
    shortName
  }
  role {
    id
    localizedName
    nameWithLinkedTo: localizedName(with: CIRCLE_LINKED_TO)
    nameWithLinkedFrom: localizedName(with: CIRCLE_LINKED_FROM)
    isSupported
    innerCircle {
      id
    }
  }
  person {
    id
    name
  }
  ...Group_projects
  ...ProjectsStatusColumn_projects
}

fragment ProjectsStatusColumn_circle on Circle {
  ...DraggableProjectCard_circle
}

fragment ProjectsStatusColumn_projects on Project {
  id
  status
  ...DraggableProjectCard_project
}

fragment RoleAccountabilitySelector_role on Role {
  isLinked
  purpose
  accountabilities {
    edges {
      node {
        id
        description(format: PLAIN)
      }
    }
  }
}

fragment RoleAndPersonSelector_circle on Circle {
  individualAction {
    id
  }
  supportedRole {
    id
    isExpanded
    assignments {
      edges {
        node {
          person {
            id
            ...PeopleSelector_people
          }
          id
        }
      }
    }
    ...RoleSelector_roles
  }
  roles(includeFocusRoles: true) {
    edges {
      node {
        id
        isIndividualAction
        assignments {
          edges {
            node {
              person {
                id
                ...PeopleSelector_people
              }
              id
            }
          }
        }
        ...RoleSelector_roles
      }
    }
  }
  members {
    edges {
      node {
        id
        ...PeopleSelector_people
      }
    }
  }
}

fragment RoleSelector_roles on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE_FOR_CORE_ROLES)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}

fragment RoleSelector_roles_4EQjyB on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}

fragment TacticalMeetingProjectsHeader_role on Role {
  databaseId
  organization {
    projectsGridEnabled
    id
  }
  innerCircle {
    databaseId
    viewerCanAddProjects
    id
  }
  circle {
    databaseId
    viewerCanAddProjects
    id
  }
}

fragment TacticalMeetingProjectsView_role on Role {
  databaseId
  organization {
    databaseId
    isApmBetaEnabled
    ...CreateProjectModal_organization
    id
  }
  innerCircle {
    id
    databaseId
    supportedRole {
      id
    }
    ...ProjectsGroupsOrGrid_circle
    ...CreateProjectModal_circle
  }
  projects(first: 250, source: INTERNAL) {
    edges {
      node {
        id
        ...ProjectsGroupsOrGrid_projects
        ...UpdateProjectModal_project
      }
    }
  }
  ...TacticalMeetingProjectsHeader_role
}

fragment TensionEditForm_viewer on Person {
  ...PersonRoleSelector_person
}

fragment UpdateProjectModal_project on Project {
  id
  databaseId
  description(format: MARKDOWN)
  rawDescription: description(format: RAW)
  link
  visibility
  value
  effort
  status
  note
  parentGoalOrTarget
  viewerCanAddActions
  viewerCanUpdate
  waitingOnWho
  waitingOnWhat
  accountability {
    id
  }
  circle {
    databaseId
    supportedRole {
      id
    }
    organization {
      isWysiwygEnabled
      id
    }
    id
  }
  person {
    id
  }
  role {
    id
  }
}

fragment UpdateTensionInlineBox_viewer on Person {
  databaseId
  ...TensionEditForm_viewer
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "processed",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Alert",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "severity",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanUpdate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privateToPerson",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "backUrl",
      "value": "/my_projects"
    }
  ],
  "kind": "ScalarField",
  "name": "projectDetailsPagePath",
  "storageKey": "projectDetailsPagePath(backUrl:\"/my_projects\")"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLinked",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isApmBetaEnabled",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDisplayedAsCircle",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFocus",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "governanceEnabled",
  "storageKey": null
},
v17 = {
  "alias": "nameWithMode",
  "args": [
    {
      "kind": "Literal",
      "name": "with",
      "value": "CIRCLE_FOR_CORE_ROLES"
    }
  ],
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": "localizedName(with:\"CIRCLE_FOR_CORE_ROLES\")"
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Circle",
  "kind": "LinkedField",
  "name": "circle",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "supportedRole",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purpose",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountabilityConnection",
  "kind": "LinkedField",
  "name": "accountabilities",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountabilityEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Accountability",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "format",
                  "value": "PLAIN"
                }
              ],
              "kind": "ScalarField",
              "name": "description",
              "storageKey": "description(format:\"PLAIN\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isROIEnabled",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWysiwygEnabled",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v24 = [
  (v5/*: any*/),
  (v2/*: any*/),
  (v23/*: any*/)
],
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "AssignmentConnection",
  "kind": "LinkedField",
  "name": "assignments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignmentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Assignment",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": (v24/*: any*/),
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v26 = [
  (v5/*: any*/)
],
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanAddProjects",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "MARKDOWN"
    }
  ],
  "kind": "ScalarField",
  "name": "description",
  "storageKey": "description(format:\"MARKDOWN\")"
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roi",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privateToCircle",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentGoalOrTarget",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentGoalOrTargetDescription",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddProjectPayload",
        "kind": "LinkedField",
        "name": "addProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectEdge",
            "kind": "LinkedField",
            "name": "projectEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tension",
                    "kind": "LinkedField",
                    "name": "tension",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Circle",
                    "kind": "LinkedField",
                    "name": "circle",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "supportedRole",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TacticalMeetingProjectsView_role"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Project_project"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddProjectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddProjectPayload",
        "kind": "LinkedField",
        "name": "addProject",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectEdge",
            "kind": "LinkedField",
            "name": "projectEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v5/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Circle",
                    "kind": "LinkedField",
                    "name": "circle",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "supportedRole",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Person",
                                "kind": "LinkedField",
                                "name": "viewer",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "first",
                                        "value": 100
                                      }
                                    ],
                                    "concreteType": "CircleConnection",
                                    "kind": "LinkedField",
                                    "name": "circles",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "CircleEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Circle",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v5/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Role",
                                                "kind": "LinkedField",
                                                "name": "supportedRole",
                                                "plural": false,
                                                "selections": [
                                                  (v5/*: any*/),
                                                  (v14/*: any*/),
                                                  (v15/*: any*/),
                                                  (v2/*: any*/),
                                                  (v16/*: any*/),
                                                  (v17/*: any*/),
                                                  (v18/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v2/*: any*/),
                                              (v12/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "isGovernanceEnabled",
                                                "storageKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": "circles(first:100)"
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RoleConnection",
                                    "kind": "LinkedField",
                                    "name": "roles",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "RoleEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Role",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Circle",
                                                "kind": "LinkedField",
                                                "name": "circle",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Role",
                                                    "kind": "LinkedField",
                                                    "name": "supportedRole",
                                                    "plural": false,
                                                    "selections": [
                                                      (v14/*: any*/),
                                                      (v5/*: any*/),
                                                      (v2/*: any*/)
                                                    ],
                                                    "storageKey": null
                                                  },
                                                  (v5/*: any*/),
                                                  (v2/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v5/*: any*/),
                                              (v14/*: any*/),
                                              (v15/*: any*/),
                                              (v2/*: any*/),
                                              (v16/*: any*/),
                                              {
                                                "alias": "nameWithMode",
                                                "args": [
                                                  {
                                                    "kind": "Literal",
                                                    "name": "with",
                                                    "value": "CIRCLE"
                                                  }
                                                ],
                                                "kind": "ScalarField",
                                                "name": "localizedName",
                                                "storageKey": "localizedName(with:\"CIRCLE\")"
                                              },
                                              (v11/*: any*/),
                                              (v19/*: any*/),
                                              (v20/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isGoalsEnabled",
                                "storageKey": null
                              },
                              (v21/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "supportableGoalsAndTargets",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "onRestrictedPlan",
                                "storageKey": null
                              },
                              {
                                "alias": "showAccountabilities",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "name",
                                    "value": "accountabilities"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "featureEnabled",
                                "storageKey": "featureEnabled(name:\"accountabilities\")"
                              },
                              (v22/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "projectsGridEnabled",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Circle",
                            "kind": "LinkedField",
                            "name": "innerCircle",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "supportedRole",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isExpanded",
                                    "storageKey": null
                                  },
                                  (v25/*: any*/),
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  (v2/*: any*/),
                                  (v16/*: any*/),
                                  (v17/*: any*/),
                                  (v18/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "circleLeadRole",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentConnection",
                                    "kind": "LinkedField",
                                    "name": "assignments",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AssignmentEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Assignment",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Person",
                                                "kind": "LinkedField",
                                                "name": "person",
                                                "plural": false,
                                                "selections": [
                                                  (v5/*: any*/),
                                                  (v23/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v5/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "individualAction",
                                "plural": false,
                                "selections": (v26/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "includeFocusRoles",
                                    "value": true
                                  }
                                ],
                                "concreteType": "RoleConnection",
                                "kind": "LinkedField",
                                "name": "roles",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RoleEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Role",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v5/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isIndividualAction",
                                            "storageKey": null
                                          },
                                          (v25/*: any*/),
                                          (v14/*: any*/),
                                          (v15/*: any*/),
                                          (v2/*: any*/),
                                          (v16/*: any*/),
                                          (v17/*: any*/),
                                          (v18/*: any*/),
                                          (v11/*: any*/),
                                          (v19/*: any*/),
                                          (v20/*: any*/)
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "roles(includeFocusRoles:true)"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PersonConnection",
                                "kind": "LinkedField",
                                "name": "members",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PersonEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Person",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": (v24/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v27/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 250
                              },
                              {
                                "kind": "Literal",
                                "name": "source",
                                "value": "INTERNAL"
                              }
                            ],
                            "concreteType": "ProjectConnection",
                            "kind": "LinkedField",
                            "name": "projects",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ProjectEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Project",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      (v6/*: any*/),
                                      (v28/*: any*/),
                                      (v29/*: any*/),
                                      (v30/*: any*/),
                                      (v9/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Circle",
                                        "kind": "LinkedField",
                                        "name": "circle",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Role",
                                            "kind": "LinkedField",
                                            "name": "supportedRole",
                                            "plural": false,
                                            "selections": (v26/*: any*/),
                                            "storageKey": null
                                          },
                                          (v5/*: any*/),
                                          (v2/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "shortName",
                                            "storageKey": null
                                          },
                                          (v12/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Organization",
                                            "kind": "LinkedField",
                                            "name": "organization",
                                            "plural": false,
                                            "selections": [
                                              (v22/*: any*/),
                                              (v5/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Role",
                                        "kind": "LinkedField",
                                        "name": "role",
                                        "plural": false,
                                        "selections": [
                                          (v5/*: any*/),
                                          (v12/*: any*/),
                                          {
                                            "alias": "nameWithLinkedTo",
                                            "args": [
                                              {
                                                "kind": "Literal",
                                                "name": "with",
                                                "value": "CIRCLE_LINKED_TO"
                                              }
                                            ],
                                            "kind": "ScalarField",
                                            "name": "localizedName",
                                            "storageKey": "localizedName(with:\"CIRCLE_LINKED_TO\")"
                                          },
                                          {
                                            "alias": "nameWithLinkedFrom",
                                            "args": [
                                              {
                                                "kind": "Literal",
                                                "name": "with",
                                                "value": "CIRCLE_LINKED_FROM"
                                              }
                                            ],
                                            "kind": "ScalarField",
                                            "name": "localizedName",
                                            "storageKey": "localizedName(with:\"CIRCLE_LINKED_FROM\")"
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isSupported",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Circle",
                                            "kind": "LinkedField",
                                            "name": "innerCircle",
                                            "plural": false,
                                            "selections": (v26/*: any*/),
                                            "storageKey": null
                                          },
                                          (v11/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Person",
                                        "kind": "LinkedField",
                                        "name": "person",
                                        "plural": false,
                                        "selections": [
                                          (v5/*: any*/),
                                          (v23/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "avatarUrl",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Organization",
                                        "kind": "LinkedField",
                                        "name": "organization",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "viewerIsMember",
                                            "storageKey": null
                                          },
                                          (v5/*: any*/),
                                          (v13/*: any*/),
                                          (v21/*: any*/),
                                          (v22/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v7/*: any*/),
                                      (v8/*: any*/),
                                      (v10/*: any*/),
                                      (v31/*: any*/),
                                      (v32/*: any*/),
                                      (v33/*: any*/),
                                      (v34/*: any*/),
                                      (v35/*: any*/),
                                      {
                                        "alias": "rawDescription",
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "format",
                                            "value": "RAW"
                                          }
                                        ],
                                        "kind": "ScalarField",
                                        "name": "description",
                                        "storageKey": "description(format:\"RAW\")"
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "value",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "effort",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "viewerCanAddActions",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "waitingOnWho",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "waitingOnWhat",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Accountability",
                                        "kind": "LinkedField",
                                        "name": "accountability",
                                        "plural": false,
                                        "selections": (v26/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "projects(first:250,source:\"INTERNAL\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Circle",
                            "kind": "LinkedField",
                            "name": "circle",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v27/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Organization",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v5/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v29/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/),
                  (v33/*: any*/),
                  (v30/*: any*/),
                  (v34/*: any*/),
                  (v35/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Person",
                    "kind": "LinkedField",
                    "name": "person",
                    "plural": false,
                    "selections": [
                      (v23/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v28/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tension",
                    "kind": "LinkedField",
                    "name": "tension",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "61b82df26ca46a1670c34ee1e9d17bfa",
    "metadata": {},
    "name": "AddProjectMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0a863d63f5f6874747396eed744bc013';

module.exports = node;
