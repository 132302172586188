/**
 * @flow
 * @relayHash 5dcb81ef0c136d14be281d1b2b9248b7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProfileProjectsView_organization$ref = any;
type ProfileProjectsView_person$ref = any;
export type ProfileProjectsQueryVariables = {|
  orgDatabaseId: string,
  personDatabaseId: string,
|};
export type ProfileProjectsQueryResponse = {|
  +organization: ?{|
    +person: ?{|
      +$fragmentRefs: ProfileProjectsView_person$ref
    |},
    +$fragmentRefs: ProfileProjectsView_organization$ref,
  |}
|};
export type ProfileProjectsQuery = {|
  variables: ProfileProjectsQueryVariables,
  response: ProfileProjectsQueryResponse,
|};
*/


/*
query ProfileProjectsQuery(
  $orgDatabaseId: String!
  $personDatabaseId: String!
) {
  organization(databaseId: $orgDatabaseId) {
    ...ProfileProjectsView_organization
    person(databaseId: $personDatabaseId) {
      ...ProfileProjectsView_person
      id
    }
    id
  }
}

fragment CircleAndRoleSelector_viewer on Person {
  circles(first: 100) {
    edges {
      node {
        id
        supportedRole {
          id
          isDisplayedAsCircle
          ...RoleSelector_roles
        }
      }
    }
  }
  ...PersonRoleSelector_person
  ...PersonCircleSelector_person
}

fragment CircleSelector_circles on Circle {
  id
  databaseId
  localizedName
  isGovernanceEnabled
  supportedRole {
    isDisplayedAsCircle
    id
  }
}

fragment CreateProjectModal_organization on Organization {
  ...ProjectFormModalFragmented_organization
}

fragment DraggableProjectCard_project on Project {
  id
  viewerCanUpdate
  ...ProjectCard_project
}

fragment Group_projects on Project {
  id
  databaseId
  status
  description(format: MARKDOWN)
  createdAt
  roi
  organization {
    viewerIsMember
    id
  }
  circle {
    shortName
    id
  }
  ...Project_project
}

fragment NoteTooltip_organization on Organization {
  ...WYSIWYGViewerWithFeatureFlag_organization
}

fragment PersonAvatar_person on Person {
  avatarUrl
  name
}

fragment PersonCircleSelector_person on Person {
  circles(first: 100) {
    edges {
      node {
        ...CircleSelector_circles
        id
      }
    }
  }
}

fragment PersonRoleSelector_person on Person {
  roles {
    edges {
      node {
        circle {
          supportedRole {
            isDisplayedAsCircle
            id
          }
          id
        }
        ...RoleSelector_roles_4EQjyB
        id
      }
    }
  }
}

fragment ProfileProjectsView_organization on Organization {
  databaseId
  projectsGridEnabled
  ...CreateProjectModal_organization
}

fragment ProfileProjectsView_person on Person {
  id
  databaseId
  viewerCanUpdate
  projects(first: 250, includeUnfilledAsCircleLead: true) {
    edges {
      node {
        id
        ...UpdateProjectModal_project
        ...ProjectsGroupsOrGrid_projects
      }
    }
  }
}

fragment ProjectCard_project on Project {
  databaseId
  status
  createdAt
  description(format: MARKDOWN)
  privateToCircle
  viewerCanUpdate
  roi
  parentGoalOrTarget
  parentGoalOrTargetDescription
  role {
    localizedName
    id
  }
  organization {
    isROIEnabled
    id
  }
  person {
    name
    ...PersonAvatar_person
    id
  }
  circle {
    id
    localizedName
  }
  ...ProjectLinkIcon_project
  ...ProjectDescription_project
}

fragment ProjectDescription_project on Project {
  description(format: MARKDOWN)
  organization {
    ...WYSIWYGViewerWithFeatureFlag_organization
    id
  }
}

fragment ProjectDetails_project on Project {
  databaseId
  createdAt
  privateToCircle
  privateToPerson
  link
  note
  roi
  parentGoalOrTarget
  parentGoalOrTargetDescription
  organization {
    isROIEnabled
    ...NoteTooltip_organization
    id
  }
  person {
    name
    id
  }
  circle {
    localizedName
    id
  }
  role {
    localizedName
    id
  }
}

fragment ProjectEditForm_organization on Organization {
  databaseId
  isGoalsEnabled
  isROIEnabled
  supportableGoalsAndTargets
  onRestrictedPlan
  showAccountabilities: featureEnabled(name: "accountabilities")
  ...WYSIWYGTextareaWithFeatureFlag_organization
}

fragment ProjectEditForm_viewer on Person {
  ...ProjectOwnerSelector_viewer
  roles {
    edges {
      node {
        id
        ...RoleAccountabilitySelector_role
      }
    }
  }
}

fragment ProjectFormModalFragmented_organization on Organization {
  viewer {
    ...ProjectFormModalView_viewer
    id
  }
  ...ProjectFormModalView_organization
}

fragment ProjectFormModalView_organization on Organization {
  ...ProjectEditForm_organization
}

fragment ProjectFormModalView_viewer on Person {
  ...ProjectEditForm_viewer
  ...UpdateTensionInlineBox_viewer
}

fragment ProjectLinkIcon_project on Project {
  link
}

fragment ProjectOwnerSelector_viewer on Person {
  ...CircleAndRoleSelector_viewer
}

fragment Project_project on Project {
  id
  status
  viewerCanUpdate
  visibility
  privateToPerson
  projectDetailsPagePath(backUrl: "/my_projects")
  role {
    isLinked
    id
  }
  circle {
    databaseId
    id
  }
  organization {
    isApmBetaEnabled
    id
  }
  ...ProjectDetails_project
  ...ProjectDescription_project
}

fragment ProjectsGroupsOrGrid_projects on Project {
  status
  description(format: MARKDOWN)
  createdAt
  roi
  privateToPerson
  circle {
    supportedRole {
      id
    }
    id
    databaseId
    shortName
  }
  role {
    id
    localizedName
    nameWithLinkedTo: localizedName(with: CIRCLE_LINKED_TO)
    nameWithLinkedFrom: localizedName(with: CIRCLE_LINKED_FROM)
    isSupported
    innerCircle {
      id
    }
  }
  person {
    id
    name
  }
  ...Group_projects
  ...ProjectsStatusColumn_projects
}

fragment ProjectsStatusColumn_projects on Project {
  id
  status
  ...DraggableProjectCard_project
}

fragment RoleAccountabilitySelector_role on Role {
  isLinked
  purpose
  accountabilities {
    edges {
      node {
        id
        description(format: PLAIN)
      }
    }
  }
}

fragment RoleSelector_roles on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE_FOR_CORE_ROLES)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}

fragment RoleSelector_roles_4EQjyB on Role {
  id
  isDisplayedAsCircle
  isFocus
  databaseId
  governanceEnabled
  nameWithMode: localizedName(with: CIRCLE)
  circle {
    id
    databaseId
    supportedRole {
      id
      databaseId
    }
  }
}

fragment TensionEditForm_viewer on Person {
  ...PersonRoleSelector_person
}

fragment UpdateProjectModal_project on Project {
  id
  databaseId
  description(format: MARKDOWN)
  rawDescription: description(format: RAW)
  link
  visibility
  value
  effort
  status
  note
  parentGoalOrTarget
  viewerCanAddActions
  viewerCanUpdate
  waitingOnWho
  waitingOnWhat
  accountability {
    id
  }
  circle {
    databaseId
    supportedRole {
      id
    }
    organization {
      isWysiwygEnabled
      id
    }
    id
  }
  person {
    id
  }
  role {
    id
  }
}

fragment UpdateTensionInlineBox_viewer on Person {
  databaseId
  ...TensionEditForm_viewer
}

fragment WYSIWYGTextareaWithFeatureFlag_organization on Organization {
  databaseId
  isWysiwygEnabled
}

fragment WYSIWYGViewerWithFeatureFlag_organization on Organization {
  isWysiwygEnabled
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orgDatabaseId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personDatabaseId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "orgDatabaseId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "databaseId",
    "variableName": "personDatabaseId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDisplayedAsCircle",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFocus",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "governanceEnabled",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLinked",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isROIEnabled",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isWysiwygEnabled",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerCanUpdate",
  "storageKey": null
},
v13 = [
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProfileProjectsView_person"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfileProjectsView_organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "projectsGridEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 100
                  }
                ],
                "concreteType": "CircleConnection",
                "kind": "LinkedField",
                "name": "circles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CircleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Circle",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "supportedRole",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v3/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": "nameWithMode",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "with",
                                    "value": "CIRCLE_FOR_CORE_ROLES"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "localizedName",
                                "storageKey": "localizedName(with:\"CIRCLE_FOR_CORE_ROLES\")"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Circle",
                                "kind": "LinkedField",
                                "name": "circle",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Role",
                                    "kind": "LinkedField",
                                    "name": "supportedRole",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isGovernanceEnabled",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "circles(first:100)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Circle",
                            "kind": "LinkedField",
                            "name": "circle",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "supportedRole",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v4/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v3/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": "nameWithMode",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "with",
                                "value": "CIRCLE"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "localizedName",
                            "storageKey": "localizedName(with:\"CIRCLE\")"
                          },
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "purpose",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountabilityConnection",
                            "kind": "LinkedField",
                            "name": "accountabilities",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AccountabilityEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Accountability",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      {
                                        "alias": null,
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "format",
                                            "value": "PLAIN"
                                          }
                                        ],
                                        "kind": "ScalarField",
                                        "name": "description",
                                        "storageKey": "description(format:\"PLAIN\")"
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isGoalsEnabled",
            "storageKey": null
          },
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "supportableGoalsAndTargets",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "onRestrictedPlan",
            "storageKey": null
          },
          {
            "alias": "showAccountabilities",
            "args": [
              {
                "kind": "Literal",
                "name": "name",
                "value": "accountabilities"
              }
            ],
            "kind": "ScalarField",
            "name": "featureEnabled",
            "storageKey": "featureEnabled(name:\"accountabilities\")"
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 250
                  },
                  {
                    "kind": "Literal",
                    "name": "includeUnfilledAsCircleLead",
                    "value": true
                  }
                ],
                "concreteType": "ProjectConnection",
                "kind": "LinkedField",
                "name": "projects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "format",
                                "value": "MARKDOWN"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": "description(format:\"MARKDOWN\")"
                          },
                          {
                            "alias": "rawDescription",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "format",
                                "value": "RAW"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": "description(format:\"RAW\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "link",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "visibility",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effort",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "note",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "parentGoalOrTarget",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewerCanAddActions",
                            "storageKey": null
                          },
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "waitingOnWho",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "waitingOnWhat",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Accountability",
                            "kind": "LinkedField",
                            "name": "accountability",
                            "plural": false,
                            "selections": (v13/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Circle",
                            "kind": "LinkedField",
                            "name": "circle",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "supportedRole",
                                "plural": false,
                                "selections": (v13/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Organization",
                                "kind": "LinkedField",
                                "name": "organization",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "shortName",
                                "storageKey": null
                              },
                              (v8/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Person",
                            "kind": "LinkedField",
                            "name": "person",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatarUrl",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "role",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": "nameWithLinkedTo",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "with",
                                    "value": "CIRCLE_LINKED_TO"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "localizedName",
                                "storageKey": "localizedName(with:\"CIRCLE_LINKED_TO\")"
                              },
                              {
                                "alias": "nameWithLinkedFrom",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "with",
                                    "value": "CIRCLE_LINKED_FROM"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "localizedName",
                                "storageKey": "localizedName(with:\"CIRCLE_LINKED_FROM\")"
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isSupported",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Circle",
                                "kind": "LinkedField",
                                "name": "innerCircle",
                                "plural": false,
                                "selections": (v13/*: any*/),
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "roi",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "privateToPerson",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Organization",
                            "kind": "LinkedField",
                            "name": "organization",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "viewerIsMember",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isApmBetaEnabled",
                                "storageKey": null
                              },
                              (v10/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "backUrl",
                                "value": "/my_projects"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "projectDetailsPagePath",
                            "storageKey": "projectDetailsPagePath(backUrl:\"/my_projects\")"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "privateToCircle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "parentGoalOrTargetDescription",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "projects(first:250,includeUnfilledAsCircleLead:true)"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "5dcb81ef0c136d14be281d1b2b9248b7",
    "metadata": {},
    "name": "ProfileProjectsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eaf6c6c72ac22f0d1fe2f30a414166da';

module.exports = node;
