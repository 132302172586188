// @flow
import React, {type Node} from 'react'
import HotwireLink from 'components/ui/HotwireLink'
import {useOrganizationContext} from 'contexts/OrganizationContext'
import styles from './index.scss'

function Feedback(): Node {
  const organization = useOrganizationContext()
  const path = `/organizations/${(organization || {}).databaseId}/dialogs/feedback?feature=frogbot`
  return (
    <div className={styles.feedback}>
      <div className="p-2">
        <i className="fa p-1 fa-bullhorn" />
        <span>
          {"We are always looking to improve this feature. We'd love your "}
        </span>
        <HotwireLink frame="remote-modal" href={path}>{'feedback'}</HotwireLink>
        <span>{'.'}</span>
      </div>
    </div>
  )
}

export default Feedback
