// @flow
import type {Node, Element} from 'react'
import React, {Component} from 'react'
import {Link, NavLink} from 'react-router-dom'
import classNames from 'classnames'

import AppRouter, {AppRouterContextProvider} from 'utils/AppRouter'
import styles from './index.scss'

type Props = {
  activeClassName?: string,
  children: Node,
  disabled?: boolean,
  highlightActive: boolean,
  href: string,
  onClick?: ?(*) => any,
  target?: ?string,
  title?: string,
  ...
}

class AppLink extends Component<Props> {
  static defaultProps: { disabled: boolean, highlightActive: boolean } = {
    disabled: false,
    highlightActive: false,
  }

  get restLinkProps(): Object {
    const {
      highlightActive, activeClassName, onClick, children, href,
      ...restLinkProps
    } = this.props
    return restLinkProps
  }

  get scopedPath(): string {
    return AppRouter.instance.getOrgnavScopedPath(this.path)
  }

  get path(): string {
    return this.props.href
  }

  renderNativeLink: (() => Element<"a">) = () => (
    <a
      {...this.restLinkProps}
      href={this.path}
      onClick={this.props.onClick}
      title={this.props.title || ''}
    >
      {this.props.children}
    </a>
  )

  renderRouterLink: (() => Node) = () => (
    <AppRouterContextProvider fallback={this.renderNativeLink}>
      {
        this.props.highlightActive
          ? (
            <NavLink
              {...this.restLinkProps}
              activeClassName={this.props.activeClassName}
              onClick={this.props.onClick}
              to={this.scopedPath}
            >
              {this.props.children}
            </NavLink>
          ) : (
            <Link
              {...this.restLinkProps}
              onClick={this.props.onClick}
              to={this.scopedPath}
            >
              {this.props.children}
            </Link>
          )
      }
    </AppRouterContextProvider>
  )

  /* eslint-disable react/require-render-return */
  // eslint-disable-next-line react/no-arrow-function-lifecycle
  render: (() => Element<"a"> | Node) = () => {
    if (this.props.disabled) {
      const buttonClass = classNames(styles.disabledButton, this.restLinkProps.className)
      return (
        <button
          className={buttonClass}
          type="button"
          disabled
        >
          {this.props.children}
        </button>
      )
    }

    return AppRouter.instance.isOrgnavScopedPath(this.path)
      ? this.renderRouterLink()
      : this.renderNativeLink()
  }
}

export default AppLink
